import * as React from "react"
import { Link } from "gatsby"

import "../styles/normalize.css"
import "../styles/main.css"

export default function NotFoundPage() {
  return (
    <main>
      <h1>Page not found</h1>
      <Link to="/">Go home</Link>.
    </main>
  )
}
